import React from "react"
import Layout from "../components/layout"
import { Container, Row, Col, Navbar, NavbarBrand, Table } from 'reactstrap'
import ImageGallery from 'react-image-gallery';
import Helmet from 'react-helmet'

import "../styles/react-image-gallery.css";



class BatTemplate extends React.Component {


    render() {

        let { bat } = this.props.pageContext;

        const images = bat.images.map(i => {
            return { original: i }
        })


        let headline = bat.headlineArray.join(' ');

        let features = bat.features.map(f => {
            return (
                <li>
                    <div style={{ fontWeight: "bold" }}>{f.headline}</div>
                    <div>{f.detail}</div>
                </li>
            )
        })


        return (
            <Layout>

                <Helmet
                    title={bat.year + " " + bat.manufacturer + " " + bat.series + " Review"}
                    meta={[
                        { name: 'description', content: bat.year + " " + bat.manufacturer + " " + bat.series + " review and details" },                        
                    ]}
                />

                <div className="wrapper">


                    <Container style={{ backgroundColor: "white", padding: "0" }}>
                        <Row>
                            <Col>
                                <Navbar color="dark" dark>
                                    <NavbarBrand className="mr-auto" style={{ color: "white" }}>{bat.year} {bat.manufacturer} {bat.series}</NavbarBrand>
                                </Navbar>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ padding: "25px 10%" }}>

                                <h1 style={{ paddingTop: "50px" }}>{bat.year} {bat.manufacturer} {bat.series} Review</h1>
                                <div style={{ paddingBottom: "50px" }} dangerouslySetInnerHTML={{ __html: headline }} />
                                <div style={{ maxWidth: "500px", margin: "0 auto", maxHeight: "500px", height: "500px" }}>
                                    <ImageGallery items={images} showThumbnails={false} showPlayButton={false} showBullets={true} showFullscreenButton={false} />
                                </div>


                                <div dangerouslySetInnerHTML={{ __html: bat.description }} />

                                <h4 style={{ paddingTop: "50px" }}>{bat.year} {bat.manufacturer} {bat.series} Features</h4>
                                <ul>{features}</ul>

                                <h4 style={{ paddingTop: "50px" }}>{bat.year} {bat.manufacturer} {bat.series} Details</h4>
                                <Table striped={true}>
                                        
                                    <tbody>
                                        <tr>
                                            <th scope="row">MSRP</th>
                                            <td>${bat.msrp}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Material</th>
                                            <td>{bat.material}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Drops Available</th>
                                            <td>{bat.drops.join(', ')}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Sizes Available</th>
                                            <td>{bat.sizes.join(', ')}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Certifications</th>
                                            <td>{bat.certifications.join(', ')}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Rating</th>
                                            <td>{bat.rating}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Manufacturer Website</th>
                                            <td><a href={bat.link} target="_blank" rel="nofollow noopener noreferrer">{bat.manufacturer}</a></td>
                                        </tr>


                                    </tbody>
                                </Table>


                            </Col>
                        </Row>

                    </Container>
                </div>
            </Layout>
                )
            }
        
        
        }
        
export default BatTemplate


/*

                                {bat.rating > 0 && 
                                
                                <div style={{marginTop: "100px"}}>*<a href="http://www.justbatreviews.com" target="_blank" rel="nofollow">Bat Digest</a> (formerly known as Just Bat Reviews) is an impartial bat review site where you can purchase their bat performance test data.</div>
                                
                                }

*/